$primary-color: #3182CE;

.p-button {
    height: 40px !important;
    justify-content: center;
    background: $primary-color;
    border: 1px solid $primary-color;
    font-weight: 500;

    &.p-button-rounded {
        border-radius: 20px !important;
    }

    &.p-button-secondary {
        color: #111 !important;
        border: 1px solid #E2E8F0 !important;
        background: #FFF !important;
    }

    &:enabled:hover {
        background: $primary-color;
        border: 1px solid $primary-color;
    }
}

.p-inputtext {
    color: #111 !important;
    height: 40px;
    border-radius: 20px !important;
    border: 1px solid #E2E8F0 !important;
    background: #F8FAFC !important;
    padding: 10px 16px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    width: 100%;
}

input::-webkit-input-placeholder {
    color: #DBDFE8 !important;
}

.p-inputtext:enabled:focus {
    box-shadow: 0 0 0 1px rgba(13, 110, 253, 0.25);
}

input:disabled {
    opacity: 1 !important;
    background: #e2e8f0 !important;
    color: #111;
}

.p-dropdown span {
    padding: 10px 0px 10px 16px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #111 !important;
    border: unset !important;
}

.p-dropdown {
    width: 100%;
    border-radius: 20px !important;
    background: #f8fafc !important;
    border: 1px solid #E2E8F0 !important;
}




.p-dropdown .p-dropdown-label.p-placeholder {
    color: #dbdfe8 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.p-dropdown:not(.p-disabled):hover {
    box-shadow: 0 0 0 1px rgba(13, 110, 253, 0.25) !important;
    border-color: rgba(13, 110, 253, 0.25) !important;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 1px rgba(13, 110, 253, 0.25) !important;
    border-color: rgba(13, 110, 253, 0.25) !important;
}

textarea::placeholder {
    color: #DBDFE8 !important;
}

.custom-textarea {
    height: unset;
}

a {
    color: $primary-color;
    text-decoration: none;
}

.p-dialog {
    .p-dialog-header {
        border-top-right-radius: 20px !important;
        border-top-left-radius: 20px !important;

        .p-dialog-header-icon {
            height: 24px !important;
            width: 24px !important;
            color: #111 !important;
            border: 2px solid;
            border-radius: 20px !important;
        }

        .p-dialog-header-icon:enabled:hover {
            color: #111 !important;
            border: 2px solid;
            border-radius: 20px !important;
            background-color: #FFF !important;
        }
    }

    // .p-dialog-content:last-of-type {
    //     border-bottom-right-radius: 20px !important;
    //     border-bottom-left-radius: 20px !important;
    // }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #111;
}

.p-dropdown-items  {
    padding-left: 0px !important;
}