@import './assets/scss/_generate.scss';
@import './assets/scss/custom-primeng.scss';
@import './assets/fonts/font-face.scss';

* {
  font-family: 'Gen Jyuu Gothic', Roboto, Arial, sans-serif !important;
}

@media screen and (max-width: 575px) {
  * {
    font-family: 'Gen Jyuu Gothic', Roboto, Arial, sans-serif !important;
  }
}

html {
  font-size: 14px !important;
}

html *::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
  height: 5px;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: rgba(0, 0, 0, 0.35);
  }
}

html *::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: rgba(241, 241, 241, 0.88);
}

.bg-light {
  margin: 0px !important;
}
.no-scroll {
  overflow: hidden !important;
}

.loading-spinner-wrap .p-progress-spinner {
  width: 45px;
  height: 45px;
}

.color-000 {
  color: #000 !important;
}

.pointer {
  cursor: pointer;
}

.header-content .key-message h3 {
  font-family: 'Staatliches' !important;
}

.b-r-8 {
  border-radius: 8px !important;
  overflow: hidden !important;
}
