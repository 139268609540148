@import './_variables.scss';

// render magrin,pading : VD  m-r-5, p-r-10
@for $space from 0 through 500 {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }

    .p-x-#{$space} {
      padding-left: #{$space}px !important;
      padding-right: #{$space}px !important;
    }

    .p-y-#{$space} {
      padding-top: #{$space}px !important;
      padding-bottom: #{$space}px !important;
    }
  }
}

// render width, height : VD  w-5, h-5
@for $space from 0 through 500 {
  .width-#{$space} {
    width: #{$space}px !important;
  }

  .height-#{$space} {
    height: #{$space}px !important;
  }

  .min-w-#{$space} {
    min-width: #{$space}px !important;
  }

  .min-h-#{$space} {
    min-height: #{$space}px !important;
  }

  .max-w-#{$space} {
    max-width: #{$space}px !important;
  }

  .max-h-#{$space} {
    max-height: #{$space}px !important;
  }

  .pd-#{$space} {
    padding: #{$space}px !important;
  }

  .mg-#{$space} {
    margin: #{$space}px !important;
  }
}

$line-camp: 2, 3, 4, 5, 6;

@each $name in $line-camp {
  .line-camp-#{$name} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: #{$name} !important;
  }
}

$font-weight: 100, 200, 300, 400, 500, 600, 700, 800, 900, bold;

@each $name in $font-weight {
  .f-w-#{$name} {
    font-weight: #{$name} !important;
  }
}

// $font-size: 6, 8, 10, 12, 13, 14, 16, 18, 20, 24;
@for $space from 6 through 56 {
  // @each $name in $font-size {
    .f-s-#{$space} {
      font-size: #{$space}px !important;
    }
  // }
}

$color: 111, 999, 718096, A9A9A9, fff, F85D5D, 3182CE, eee;
@each $name in $color {
  .color-#{$name} {
    color: #{'#' + $name} !important;
  }
}
