@font-face {
  font-family: 'Gen Jyuu Gothic';
  src: url(./GenJyuuGothic-Regular.ttf) format('truetype');
}

// @font-face {
//   font-family: Gen Jyuu Gothic;
//   src: url(./GenSenRounded-EL.ttc);
//   font-weight: 200;
// }

// @font-face {
//   font-family: Gen Jyuu Gothic;
//   src: url(./GenSenRounded-L.ttc);
//   font-weight: 300;
// }

// @font-face {
//   font-family: Gen Jyuu Gothic;
//   src: url(./GenSenRounded-R.ttc);
//   font-weight: 400;
// }

// @font-face {
//   font-family: Gen Jyuu Gothic;
//   src: url(./GenSenRounded-B.ttc);
//   font-weight: 500;
// }

// @font-face {
//   font-family: Gen Jyuu Gothic;
//   src: url(./GenSenRounded-M.ttc);
//   font-weight: 600;
// }

// @font-face {
//   font-family: Gen Jyuu Gothic;
//   src: url(./GenSenRounded-H.ttc);
//   font-weight: 900;
// }
